<template>
    <div class="qr-scanner">
        <v-row justify="center">
            <v-col md="8">
                <h3 class="mx-4 my-2">Scan your QR code</h3>
                <div class="mx-4 my-2 yellow-frame">
                    
                    <qrcode-stream @decode="onDecode" :camera="camera">
                        <div v-if="isDecoding" class="validation-pending">
                            <div class="text-center">
                                <v-progress-circular
                                    indeterminate
                                    size="64"
                                    color="primary"
                                ></v-progress-circular>

                            </div>

                        </div>
                    </qrcode-stream>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import Mixins from "@utils/mixins";
import { mapGetters } from 'vuex';
import moment from "moment";

export default{
    name: 'AttendanceQRScanner',
    mixins: [Mixins.essentials],
    components:{
        QrcodeStream,
    },
    data(){
        return{
            isDecoding: false,
            camera: 'auto',
            viewAttendanceDate: null,
        }
    },
    computed:{
        ...mapGetters(["allRooms"]),

    },

    methods:{
        async onDecode(decodedString){
            try {
                this.isDecoding = true
                this.camera = "off"
                const qrObj = JSON.parse(atob(decodedString)); 
                //make api call
                const url = this.endpoints.classroomAttendanceViewSet + 'attendance-scanner/'
                const response =  await this.api.call(this.essentials,url, this.api.Methods.POST,qrObj)
                if (response){
                    if(response.marked_present){
                        this.showSnackbar({
                            title: "You were marked present",
                            type: "success",
                        });
                    }
                    else if(response.picked_up){
                        this.showSnackbar({
                            title: "You were marked as picked up",
                            type: "success",
                        });
                    }
                    else{
                        this.showSnackbar({
                            title: "Attendance was marked and the student was picked up already. If this looks like a problem please reach out to the admin.",
                            type: "info",
                        });
                    }
                    this.$router.push({
                        name: "StudentAttendanceDaily",
                        params: {
                            roomId: qrObj.room_id,
                            studentId: this.currentUser.id,
                            daily: true,
                            startDate: this.viewAttendanceDate[0],
                            endDate: this.viewAttendanceDate[1],
                        },
                    });
                }
                else{
                    throw new Error("Invalid request")
                }
                
            }
            catch(e){
                this.showSnackbar({
                    title: "Invalid QR Code. Please try again.",
                    type: "error",
                });
            }
            finally{
                this.camera = "auto"
                this.isDecoding = false
            }
        }
    },
    created(){
        this.viewAttendanceDate = [
            moment().format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
        ];
    }
}
</script>
<style scoped>
.yellow-frame{
    border: yellow 3px solid;
}
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .8);
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
</style>